import { ValidationException } from 'shared/interfaces';

export const httpGet = <TResult,>(endpoint: string): Promise<TResult> =>
  fetch(endpoint, { method: 'GET', headers: getHeaders() })
    .then(r => handleResponse<TResult>(r))
    .catch(err => handleCatch(err) as Promise<TResult>);

export const httpPost = <TResult>(endpoint: string, data: object) =>
  fetch(endpoint, { method: 'POST', headers: getHeaders(), body: JSON.stringify(data) })
    .then(r => handleResponse<TResult>(r))
    .catch(err => handleCatch(err) as Promise<TResult>);

export const httpPut = <TResult>(endpoint: string, data: object) =>
  fetch(endpoint, { method: 'PUT', headers: getHeaders(), body: JSON.stringify(data), })
    .then(r => handleResponse<TResult>(r))
    .catch(err => handleCatch(err) as Promise<TResult>);

export const httpDelete = <TResult>(endpoint: string) =>
  fetch(endpoint, { method: 'DELETE', headers: getHeaders() })
    .then(r => handleResponse<TResult>(r))
    .catch(err => handleCatch(err) as Promise<TResult>);

export const postForm = (endpoint: string, formData: FormData) => {
  const token = getApiToken();
  const headers = !!token ? { Authorization: `Bearer ${token}` } : undefined;

  return fetch(endpoint, { method: 'POST', headers, body: formData })
    .then(r => handleResponse(r))
    .catch(err => handleCatch(err));
}

const getApiToken = (): string => localStorage.getItem('token') as string;

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getApiToken()}`,
  };
};

const handleResponse = <TResult,>(response: Response): Promise<TResult> => {
  if (!response.ok) {
    throw response;
  }

  if (response.status === 204) {
    return new Promise(res => res(null as any));
  }

  return response.json()
    .then(body => {
      return body;
    }).catch(() => {
      throw response;
    });
}

type CustomException = { message: string; errorCode: number; }

const handleCatch = (response: Response): Promise<any> => {
  console.log('handleCatch', response);

  const generateErrorObject = (body: any): CustomException => ({
    message: !!body.message ? body.message : 'Óþekkt villa kom upp',
    errorCode: !!body.errorCode ? body.errorCode : 0
  });

  const generateValidationError = (body: any): ValidationException => ({ details: body.details });

  if (!response.status) {
    throw generateErrorObject({ message: 'Get ekki tengst vefþjóni', errorCode: 1 })
  }

  // check for client error, wrap as validation error
  if (response.status === 400) {
    return response.json().then(err => {
      throw generateValidationError(err);
    });
  }

  if (!response.headers) {
    throw generateErrorObject({});
  }

  const contentType = response.headers.get('Content-Type');

  if (contentType !== 'application/json') {
    throw generateErrorObject({});
  }

  return response.json().then(body => {
    if (!body) {
      throw generateErrorObject({});
    } else {
      throw generateErrorObject({ ...body });
    }
  });
}
