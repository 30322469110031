import React from 'react';
import { Route, Routes } from 'react-router-dom';

import FrontPage from 'FrontPage/FrontPage';
import Logout from 'Logout/Logout';
import RedirectToTracks from 'ProjectWizard/RedirectToTracks';

const AuthCallback = React.lazy(() => import('./AuthCallback/AuthCallback'));
const Login = React.lazy(() => import('./Login/Login'));
const MyDashboard = React.lazy(() => import('./MyDashboard/MyDashboard'));
const MyProjects = React.lazy(() => import('./MyProjects/MyProjects'));
const MyRecordings = React.lazy(() => import('./MyRecordings/MyRecordings'));
const NewUser = React.lazy(() => import('./NewUser/NewUser'));
const NotFound = React.lazy(() => import('./NotFound/NotFound'));
const PrivateRoute = React.lazy(() => import('./shared/components/PrivateRoute'));
const ProjectSubmitted = React.lazy(() => import('./Review/ProjectSubmitted'));
const ProjectWizard = React.lazy(() => import('./ProjectWizard/ProjectWizard'));
const RecordingDetails = React.lazy(() => import('./RecordingDetails/RecordingDetails'));
const Register = React.lazy(() => import('./Register/Register'));
const SessionExpired = React.lazy(() => import('./SessionExpired/SessionExpired'));
const SpotifySearchPage = React.lazy(() => import('./Spotify/SpotifySearchPage'));
const UserCreated = React.lazy(() => import('./UserCreated/UserCreated'));
const Claims = React.lazy(() => import('./Claims'));
const MyInfo = React.lazy(() => import('./MyInfo/MyInfo'));
const MyAllocations = React.lazy(() => import('./MyAllocations'));

const AppRoutes = () => {
  return <>
    <Routes>
      <Route path="/" element={<FrontPage />} />
      <Route path="/projects/:projectId" element={<RedirectToTracks />} />
      <Route path="/projects/:projectId/*" element={<PrivateRoute><ProjectWizard /></PrivateRoute>} />
      <Route path="/dashboard" element={<PrivateRoute><MyDashboard /></PrivateRoute>} />
      <Route path="/myprojects" element={<PrivateRoute><MyProjects /></PrivateRoute>} />
      <Route path="/review/:projectId" element={<PrivateRoute><ProjectSubmitted /></PrivateRoute>} />
      <Route path="/myrecordings" element={<PrivateRoute><MyRecordings /></PrivateRoute>} />
      <Route path="/recording-details/:recordingId" element={<RecordingDetails />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/register" element={<Register />} />
      <Route path="/auth-callback" element={<AuthCallback />} />
      <Route path="/user-created" element={<UserCreated />} />
      <Route path="/session-expired" element={<SessionExpired />} />
      <Route path="/new-user" element={<NewUser />} />
      <Route path="/spotify" element={<SpotifySearchPage />} />
      <Route path="/claims/*" element={<PrivateRoute><Claims /></PrivateRoute>} />
      <Route path="/my-info" element={<PrivateRoute><MyInfo /></PrivateRoute>} />
      <Route path="/my-allocations" element={<PrivateRoute><MyAllocations /></PrivateRoute>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
};

export default AppRoutes;
