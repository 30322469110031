import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const Logout = () => <>
  <Paper style={{ paddingTop: '100px', paddingBottom: '100px' }}>
    <Grid container justifyContent="center" direction="column" spacing={3}>
      <Grid item xs>
        <Typography variant="h6" align="center">
          Þú hefur verið skráð/ur út
        </Typography>
      </Grid>
    </Grid>
  </Paper>
</>

export default Logout;
