import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "oidc-client";

import { SFHUser } from "shared/interfaces";
import { setLocalStorage, cleanLocalStorage } from "shared/helpers/localStorage";
import { AuthContext } from "shared/contexts";

export const useAuth = () => {
  const navigate = useNavigate();
  const [authData, setAuthData] = useContext(AuthContext);
  const { user: theUser, isUserFetched, isLoading, userManager } = authData;

  useEffect(() => {
    if (authData.isInit) {
      return;
    }

    userManager.events.addSilentRenewError(e => {
      // console.log('[useAuth] silent renew error', e.message);
    });

    userManager.events.addAccessTokenExpiring(e => {
      // console.log('[useAuth] addAccessTokenExpiring', e);
    });

    userManager.events.addUserLoaded(usr => {
      // console.log('[useAuth] addUserLoaded', usr);
      updateUser(toUser(usr));
    });

    userManager.events.addAccessTokenExpired(e => {
      // console.log('[useAuth]] addAccessTokenExpired', e);
      logout();
    });

    userManager.getUser().then(usr => {
      // console.log('[useAuth] getUser', usr);
      if (usr === null) {
        cleanLocalStorage();
        updateUser(null);
      } else {
        update(usr);
      }
    });

    setAuthData(old => ({ ...old, isInit: true }));
  }, []);

  const login = async () => {
    const usr = await userManager.getUser();
    if (!!usr) {
      await userManager.signoutRedirect();
      await userManager.signoutRedirectCallback();
      await userManager.signinRedirect();
    } else {
      await userManager.signinRedirect();
    }
  }

  const signinCallback = () => {
    userManager.signinRedirectCallback()
      .then(usr => {
        update(usr);
        localStorage.removeItem('dologin');

        const savedUrl = localStorage.getItem('redirect');
        const redirectUrl = !!savedUrl ? savedUrl : '/dashboard'

        if (!!savedUrl) {
          localStorage.removeItem('redirect');
        }

        navigate(redirectUrl);
      })
      .catch(() => {
        if (!localStorage.getItem('dologin')) {
          localStorage.setItem('dologin', 'true');
          cleanLocalStorage();
          navigate('/login');
        }
      });
  }

  const logout = () => {
    userManager.signoutRedirect().then(() => {
      //   console.log('[useAuth] logged out')
      cleanLocalStorage();
    });
  }

  const logOutAndRedirectToLogin = () => {
    userManager.signoutRedirect().then(() => {
      cleanLocalStorage();
      userManager.signinRedirect();
    })
  }

  const update = (usr: User) => {
    updateUser(toUser(usr));
    setLocalStorage(usr);
  }

  const updateUser = (usr: SFHUser | null) => {
    setAuthData(old => ({ ...old, user: usr, isUserFetched: true }));
  }

  const isLoggedIn = !!theUser;

  return {
    isLoading,
    isLoggedIn,
    isUserFetched,
    login,
    logout,
    logOutAndRedirectToLogin,
    signinCallback,
    user: theUser,
  };
}

const toUser = (user: User): SFHUser => {
  const userName = user.profile.preferred_username!;
  const token = user.access_token;
  const isEmailConfirmed = user.profile.email_verified!;

  return { userName, token, isEmailConfirmed };
}