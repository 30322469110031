import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { useMessageContext } from 'shared/hooks/useMessageContext';

const BottomSnackbar = () => {
  const classes = useStyles();
  const { message, resetMessage } = useMessageContext();

  const handleClose = () => resetMessage();

  return <>{!!message && !!message.message &&
    <Snackbar
      open={!!message}
      message={message.message}
      autoHideDuration={message.type === 'info' ? 6000 : null}
      // onClose={onClose}
      action={[
        <Button
          variant="text"
          color="inherit"
          size="small"
          key="close"
          onClick={handleClose}
        >Loka</Button>
      ]}
      className={classes[message.type]}
    ></Snackbar>}</>
}

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.info.dark
  },
  success: {
    backgroundColor: theme.palette.success.main
  }
}));

export default BottomSnackbar;
