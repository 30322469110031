export const appTheme = {
  palette: {
    primary: {
      main: '#007782',
      light: '#33929b',
      dark: '#00535b'
    },
    secondary: {
      main: '#e57373',
      light: '#ffa4a2',
      dark: '##af4448'
    },
    error: {
      main: '#f55'
    },
    warning: {
      main: '#ffb74d'
    },
    info: {
      main: '#d9f6ff'
    },
    success: {
      main: '#5ec668',
      light: '#92fa97',
      dark: '#25943b'
    }
  },
};
