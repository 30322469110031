import { MessageContext } from 'shared/contexts';
import { useContext } from 'react';

export const useMessageContext = () => {
  const [msg, setMsg] = useContext(MessageContext);

  const setError = (message: string): void => setMsg({ message, type: 'error' })
  const setSuccess = (message: string): void => setMsg({ message, type: 'success' })
  const setInfo = (message: string): void => setMsg({ message, type: 'info' })
  const resetMessage = () => setMsg(null);

  return { setError, setSuccess, setInfo, resetMessage, message: msg }
}