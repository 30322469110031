import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, Theme, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
  footer: {
    marginTop: theme.spacing(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(6)}px 0`,
  },
});

const Footer: React.FC<WithStyles<typeof styles>> = props => {
  const { classes } = props;

  return (
    <footer className={classes.footer}>
      <Typography variant="subtitle1" color="textSecondary" align="center">
        <a href="https://sfh.is">SFH - Samband flytjenda og hljómplötuframleiðanda</a><br />
        <a href="mailto: umsjon@hljodrit.is">Hafa samband</a>
      </Typography>
    </footer>
  );
};

export default withStyles(styles)(Footer);
