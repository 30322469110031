import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RedirectToTracks = () => {
  const { pathname } = useLocation();
  let url = `${pathname}/tracks`.replaceAll('//', '/');

  return <Navigate to={url} />;
}

export default RedirectToTracks;