interface ConnectionStrings {
  API_ENDPOINT: string,
  AUTH_ENDPOINT: string,
  FRONTEND_BASEPATH: string,
  REPERTOIRE_ENDPOINT: string;
  features: {
    claimFeature: boolean
  }
};

const development: ConnectionStrings = {
  API_ENDPOINT: 'http://localhost:5000/api',
  AUTH_ENDPOINT: 'http://auth:5100',
  FRONTEND_BASEPATH: 'http://localhost:3000',
  REPERTOIRE_ENDPOINT: 'http://localhost:5050/v1',
  features: {
    claimFeature: true
  }
};

const staging: ConnectionStrings = {
  API_ENDPOINT: 'https://beta.hljodrit.is/api',
  AUTH_ENDPOINT: 'https://sfh-staging-auth.azurewebsites.net',
  FRONTEND_BASEPATH: 'https://beta.hljodrit.is',
  REPERTOIRE_ENDPOINT: 'https://sfh-staging-repertoire.azurewebsites.net/v1',
  features: {
    claimFeature: true
  }
};

const production: ConnectionStrings = {
  API_ENDPOINT: 'https://hljodrit.is/api',
  AUTH_ENDPOINT: 'https://auth.hljodrit.is',
  FRONTEND_BASEPATH: 'https://hljodrit.is',
  REPERTOIRE_ENDPOINT: 'https://repertoire.hljodrit.is/v1',
  features: {
    claimFeature: false
  }
}

export const getEnvironmentVariables = (environmentName: string | undefined = process.env.REACT_APP_ENVIRONMENT) => {
  switch (environmentName) {
    case 'development':
      return development;
    case 'production':
      return production;
    case 'staging':
      return staging;
    default:
      return development;
  }
};


export const getEnvironmentName = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  if (!env) return 'development';
  else {
    return env;
  }
};