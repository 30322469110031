import { PlaceholderDataFunction, useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

export const useMutationRefreshCash = <T,>(
  fn: (x: any) => Promise<T>,
  arr: any[],
  getId: ((x: T) => string) | undefined = undefined
) => {
  const queryClient = useQueryClient();

  return useMutation(fn, {
    onSuccess: (data) => {
      if (!!getId) {
        const id = getId(data);
        arr = [...arr, id]
      }
      queryClient.invalidateQueries(arr);
    }
  });
}

export const useQueryWithDefaultValue = <T, A, D>(
  defaultData: D,
  arr: any[],
  fn: () => Promise<T>,
  value: A | null | undefined = undefined,
  options?: Omit<UseQueryOptions<T, unknown, T, any[]>, "queryKey" | "queryFn"> | undefined,
) => {
  const q = useQuery(arr, fn, {
    ...options,
    enabled: value !== null,
    placeholderData: defaultData as unknown as (T | PlaceholderDataFunction<T> | undefined)
  });
  const data = !!q.data ? q.data : defaultData;
  return { ...q, data };
}