import {
  AddContributionLineCommand,
  AddIsrcPrefixToProjectCommand,
  AddPerformanceLineCommand,
  AddProducerToProjectCommand,
  AddRecordingCommand,
  AddTrackCommand,
  ContributionLineType,
  Country,
  CreatePartyCommand,
  CreateProducerCommand,
  CreateProjectCommand,
  CreateSingleCommand,
  CreateUserCommand,
  Envelope,
  Instrument,
  MemberDetails,
  MemberInfo,
  Party,
  PasteContributionLinesCommand,
  PastePerformanceLinesCommand,
  PerformanceLineType,
  Producer,
  Project,
  ProjectOverview,
  Recording,
  RecordingResult,
  RecordingWithLines,
  RecordingWithPerformers,
  RemoveProducerFromProjectCommand,
  ReorderTracksCommand,
  Role,
  SearchForParties,
  RecordingSearch,
  SearchTarget,
  Single,
  SpotifyResponse,
  SubmitProjectCommand,
  Track,
  UpdatePerformanceLineCommand,
  UpdateProjectCommand,
  UpdateSingleCommand,
  UpdateTrackCommand,
  Claim,
  CreateClaimCommand,
  ChangeMyBankInfoCommand,
  ChangeMyAddressCommand
} from 'shared/interfaces';
import { getEnvironmentVariables } from 'environment';
import { httpGet as get, httpPost as post, httpDelete as del, httpPut as put, postForm } from 'shared/helpers/data.service';

const projects = getEnvironmentVariables().API_ENDPOINT;
const repertoire = getEnvironmentVariables().REPERTOIRE_ENDPOINT;
const auth = getEnvironmentVariables().AUTH_ENDPOINT;

/// Auth

export const registerUser = (data: CreateUserCommand) =>
  post<{ userName: string; email: string; }>(`${auth}/api/accounts`, data);

/// Projects

export const getInstruments = () =>
  get<Instrument[]>(`${projects}/instruments`);

export const getPerformanceRoles = () =>
  get<Role[]>(`${projects}/roles/performance`);

export const getCountries = () =>
  get<Country[]>(`${projects}/common/countries`);

export const getTracksByProjectId = (projectId: string) =>
  get<Track[]>(`${projects}/projects/tracks/${projectId}`);

export const getProjectById = (projectId: string) =>
  get<Project>(`${projects}/projects/${projectId}`);

export const getProjectSummary = (projectId: string) =>
  get<ProjectOverview>(`${projects}/projects/${projectId}/summary`);

export const getProducerById = (producerId: string) =>
  get<Producer>(`${projects}/producers/${producerId}`);

export const getMyProjects = (userId: string) =>
  get<Project[]>(`${projects}/projects/users/${userId}`);

export const deleteTrack = (trackId: string) =>
  del<Track>(`${projects}/projects/tracks/${trackId}`);

export const createProducer = (data: CreateProducerCommand) =>
  post<Producer>(`${projects}/producers`, data);

export const createProject = (command: CreateProjectCommand) =>
  post<Project>(`${projects}/projects`, command);

export const updateProject = (data: UpdateProjectCommand) =>
  put<Project>(`${projects}/projects/${data.id}`, data);

export const deleteProject = (projectId: string) =>
  del<Project>(`${projects}/projects/${projectId}`);

export const getAllPerformanceLines = (projectId: string) =>
  get<PerformanceLineType[]>(`${projects}/projects/${projectId}/credits`);

export const getContributionLines = (projectId: string) =>
  get<ContributionLineType[]>(`${projects}/projects/${projectId}/contributors`);

export const printPdf = (projectId: string) =>
  get<{ url: string }>(`${projects}/projects/pdf/${projectId}`)

export const findRecordings = (recordingName: string) =>
  get<Envelope<Recording>>(`${projects}/recordings/search?searchTerm=${recordingName}&pageSize=5000&pageNumber=1`);

export const removeProducerFromProject = (cmd: RemoveProducerFromProjectCommand) =>
  del<Project>(`${projects}/projects/${cmd.projectId}/remove-producer/${cmd.producerId}`);

export const addProducerToProject = (cmd: AddProducerToProjectCommand) =>
  post<Project>(`${projects}/projects/${cmd.projectId}/add-producer`, cmd);

export const addIsrcPrefixToProject = (cmd: AddIsrcPrefixToProjectCommand) =>
  post<Project>(`${projects}/projects/${cmd.projectId}/add-prefix`, cmd);

export const submitProject = (data: SubmitProjectCommand) =>
  put<ProjectOverview>(`${projects}/projects/${data.projectId}/review`, data);

export const addTrack = (command: AddTrackCommand) =>
  post<Track>(`${projects}/projects/tracks`, command);

export const updateTrack = (data: UpdateTrackCommand) =>
  put<Track>(`${projects}/projects/tracks/${data.id}`, data);

export const deletePerformanceLine = (lineId: string) =>
  del<string>(`${projects}/projects/credits/${lineId}`);

export const deleteContributionLine = (lineId: string) =>
  del<string>(`${projects}/projects/contributors/${lineId}`);

export const addRecording = (command: AddRecordingCommand) =>
  post<Track[]>(`${projects}/projects/tracks/add-recording`, command);

export const createSingle = (command: CreateSingleCommand) =>
  post<Single>(`${projects}/singles`, command);

export const updateSingle = (command: UpdateSingleCommand) =>
  put<Single>(`${projects}/singles/${command.projectId}`, command);

export const resortTracksRemotely = (data: ReorderTracksCommand) =>
  post<Track[]>(`${projects}/projects/tracks/resort`, data);

export const updatePerformanceLine = (data: UpdatePerformanceLineCommand) =>
  put<PerformanceLineType>(`${projects}/projects/credits/${data.trackLineId}`, data);

export const addPerformanceLine = (command: AddPerformanceLineCommand) =>
  post<PerformanceLineType>(`${projects}/projects/credits`, command);

export const pastePerformanceLines = (data: PastePerformanceLinesCommand) =>
  post<PerformanceLineType[]>(`${projects}/projects/credits/paste-credits`, data);

export const addContributionLine = (x: AddContributionLineCommand) => post<ContributionLineType>(`${projects}/projects/contributors`, x);

export const pasteContributionLines = (data: PasteContributionLinesCommand) =>
  post<ContributionLineType>(`${projects}/projects/contributors/paste-contributors`, data);

export const searchSpotify = (term: string) =>
  get<SpotifyResponse>(`${projects}/spotify/search?term=${term}`)

export const createAlbumFromSpotifyId = (spotifyAlbumId: string) =>
  post<string>(`${projects}/projects/spotify`, { spotifyAlbumId })

export const searchForProducers = (term: string, target: SearchTarget): Promise<Envelope<Producer>> => {
  if (target === SearchTarget.LOCAL) {
    return get<Envelope<Producer>>(`${projects}/producers/?searchTerm=${term}&pageSize=15&pageNumber=1`);
  }
  else {
    return get<Envelope<{
      postalAddressLine1: string;
      area: string;
      kennitala: string;
      fullName: string;
      zipCode: string;
    }>>(`${projects}/NationalRegistry/buisnesses?term=${term}`)
      .then(result => {
        const parties = { ...result };

        if (parties.objects.length === 0 || !parties.objects) {
          return { ...parties, objects: [] }
        }

        const objects: Producer[] = [...parties.objects]
          .map(p => ({
            address: p.postalAddressLine1,
            city: p.area,
            id: '',
            kennitala: p.kennitala!,
            name: p.fullName,
            zipCode: p.zipCode,
            mainContactEmail: '',
            mainContactName: '',
            mainContactPhoneNumber: '',
            isrcPrefixes: []
          }));

        return { ...parties, objects };
      });
  }
}

export const searchParties = ({ target, term }: SearchForParties, showOnlyPerformers: boolean) => {
  const endpoint = target === SearchTarget.LOCAL
    ? `${repertoire}/parties/search?searchTerm=${term}&showOnlyPerformers=${showOnlyPerformers}`
    : `${projects}/NationalRegistry/search?term=${term}`;

  const fn = get<Party[] | Envelope<Party>>(endpoint).then(result => {
    const parties = target === SearchTarget.NATIONAL_REGISTRY
      ? (result as Envelope<Party>).objects
      : result;

    return parties as Party[];
  });

  return fn;
}

/// Repertoire

export const getMyAllocations = () => get<{ allocationId: string; amountISK: string; label: string; }[]>(`${repertoire}/allocations/my-allocations`);

export const createParty = (form: CreatePartyCommand) =>
  post<Party>(`${repertoire}/parties`, form);

export const getMemberInfo = () =>
  get<MemberInfo>(`${repertoire}/members/myinfo`);

export const getMemberDetails = () =>
  get<MemberDetails | null>(`${repertoire}/members/mydetails`);

export const checkIfMemberExistsForKennitala = (kt: string) =>
  get<boolean>(`${repertoire}/members/check?kennitala=${kt}`);

export const getMyRecordings = () =>
  get<RecordingWithLines[]>(`${repertoire}/recordings/myrecordings`);

export const getRecordingDetails = (recordingId: string) =>
  get<RecordingWithPerformers>(`${repertoire}/recordings/${recordingId}/performers`);

export const getRecordingsForClaims = (s: RecordingSearch) => {
  let str = 'pagination.pageSize=500&pagination.pageNumber=1&orderBy.direction=asc&';

  if (!!s.albumName) {
    str += `albumName.value=${s.albumName}&albumName.type=STARTS_WITH&`;
  }
  if (!!s.artistName) {
    str += `artistName.value=${s.artistName}&artistName.type=STARTS_WITH&`;
  }
  if (!!s.title) {
    str += `title.value=${s.title}&title.type=STARTS_WITH&`;
  }
  if (!!s.isrc) {
    str += `isrc.value=${s.isrc}&isrc.type=STARTS_WITH&`;
  }

  return get<Envelope<RecordingResult>>(`${repertoire}/recordings/find-recordings-to-claim?${str}`);
}

export const createClaim = (command: CreateClaimCommand) => {
  const recordingIds = command.recordings.map(r => r.id);
  const roleCode = command.role.code;
  const instrumentCodes = command.instruments.map(i => i.code);
  const countryOfRecording = command.countryOfRecording.twoLetterIsoCode;

  const data = {
    recordingIds,
    roleCode,
    instrumentCodes,
    countryOfRecording,
  };

  return post<string[]>(`${repertoire}/claims`, data);
}

export const getClaims = (): Promise<Claim[]> =>
  get<Claim[]>(`${repertoire}/claims/my-claims`);

export const attachEvidences = (data: FormData) =>
  postForm(`${repertoire}/claims/attach-evidences`, data);

export const updateBankInfo = (data: ChangeMyBankInfoCommand) =>
  post<{ bank1: string; bank2: string; bank3: string; bankKennitala: string }>(`${repertoire}/parties/change-my-bank-info`, data);

export const changeAddress = (data: ChangeMyAddressCommand) =>
  post<{ street: string; city: string; zipCode: string; countryCode: string; }>(`${repertoire}/parties/change-my-address`, data);

export const sendLink = (command: { newEmailAddress: string }) =>
  post<{}>(`${repertoire}/parties/send-email-change-request-link`, command);

export const changePhoneNumber = (command: { newPhoneNumber: string }) =>
  post<{}>(`${repertoire}/parties/change-phone-number`, command);