import { UserManager } from 'oidc-client';

export type CreateProjectCommand = {
  displayArtistName: string;
  name: string;
  releaseDate: Date;
}

export type UpdateProjectCommand = {
  id: string;
  name: string;
  displayArtistName: string;
  releaseDate: Date;
}

export type RemoveProducerFromProjectCommand = {
  projectId: string;
  producerId: string;
}
export type AddProducerToProjectCommand = {
  projectId: string;
  producerId: string;
}

export type AddIsrcPrefixToProjectCommand = {
  projectId: string;
  isrcPrefixId: string;
}

export type SubmitProjectCommand = {
  projectId: string;
  isLineupComplete: boolean;
  lineupComment: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

export type AddTrackCommand = {
  projectId: string;
  name: string;
  duration: string;
}

export type UpdateTrackCommand = {
  id: string;
  name: string;
  duration: string;
}

export type AddRecordingCommand = {
  recordingId: string;
  projectId: string;
  sideNumber: number;
  trackId: string
}

export type CreateSingleCommand = {
  displayArtistName: string;
  name: string;
  releaseDate: Date;
  duration: string;
}

export type UpdateSingleCommand = {
  projectId: string;
  displayArtistName: string;
  name: string;
  releaseDate: Date;
  duration: string;
  trackId: string
}

export type ReorderTracksCommand = {
  ids: string[]
  projectId: string
}

export type UpdatePerformanceLineCommand = {
  trackLineId: string;
  primaryRoleCode: string;
  instrumentCodes: string[];
}

export type AddPerformanceLineCommand = {
  trackLineId: string;
  partyId: string;
  trackId: string;
  roleCode: string;
  instrumentCodes: string[];
}

export type PastePerformanceLinesCommand = {
  selectedTracks: string[];
  selectedTrackLines: {
    partyId: string,
    instruments: string[]
    primaryRoleCode: string
  }[]
  projectId: string
}

export type PasteContributionLinesCommand = {
  selectedTrackIds: string[];
  selectedTrackLineIds: string[];
  projectId: string;
}

export type Single = {
  project: Project,
  track: Track
}

export type CreatePartyCommand = {
  fullName: string;
  kennitala: string;
  dateOfBirth: string | null;
  email: string;
  mobile: string;
  street: string;
  city: string;
  zipCode: string;
  countryCode: string;
  pseudonym: string;
  nationality: string;
  typeOfForm: string;
  ipnNumber: string;
  dateOfDeath: string | null;
}


export enum LineView {
  PERFORMANCE_LINE = 'PERFORMANCE_LINE',
  CONTRIBUTION_LINE = 'CONTRIBUTION_LINE'
}

export enum LineupViews {
  LINE = 'LINE',
  EMPTY = 'EMPTY',
  PARTY = 'PARTY',
}

export type CreateUserCommand = {
  userName: string;
  email: string;
  password: string;
  name: string;
};

export interface RegistrationFormError {
  email: string[];
  name: string[];
  password: string[];
  userName: string[];
}

export interface SFHUser {
  userName: string;
  token: string;
  isEmailConfirmed: boolean;
}

export type AuthState = {
  user: SFHUser | null;
  isInit: boolean;
  isLoading: boolean;
  isUserFetched: boolean;
  userManager: UserManager;
};

export interface SpotifyArtist {
  name: string;
}

export interface SpotifyAlbum {
  id: string;
  images: {
    url: string
  }[];
  name: string;
  artists: SpotifyArtist[];
  releaseDate: string;
  shit: boolean;
  albumType: string;
}

export interface SpotifyResponse {
  albums: {
    items: SpotifyAlbum[]
  }
}

export type TabType = {
  url: string;
  label: string;
}

export type RecordingWithLines = {
  id: string;
  name: string;
  isrcCode: string;
  roleNameIcelandic: string;
  instruments: string[];
  recordingId: string;
}

export type Line = {
  id: string;
  trackId: string;
  party: Party;
  partyId: string;
}

export type MessageState = {
  message: string;
  type: 'error' | 'success' | 'info';
}

export interface AddContributionLineCommand {
  trackId: string | null;
  roles: string[];
  partyId: string;
}

export interface AddPerformanceLineForm {
  trackId: string;
  partyId: string;
  roleCode: string;
  instrumentCodes: string[];
}

export type ContributionLineType = Line & {
  party: Party;
  roles: Role[];
}

export interface Country {
  numericIsoCode: number;
  twoLetterIsoCode: string;
  nameIcelandic: string;
}

export interface CreateProducerCommand {
  name: string;
  kennitala: string;
  zipCode: string;
  city: string;
  address: string;
  phoneNumber: string;
  mainContactName: string;
  mainContactEmail: string;
  mainContactPhoneNumber: string;
}

export interface Envelope<T> {
  totalNumber: number;
  currentPage: number;
  maximumPage: number;
  pageSize: number;
  objects: T[];
}

export interface Exception {
  errorCode: number;
  message: string;
  details: ValidationError;
}

export interface Instrument {
  code: string;
  nameIcelandic: string;
  hints: string;
}

export interface IsrcPrefix {
  id: string;
  registrantCode: string;
  countryCode: string;
  lastUsedDesignationCode: number;
  lastUsedYear: number;
}

export interface MemberInfo {
  isMember: boolean;
  name: string;
  kennitala: string;
  memberId: string;
  isVerified: boolean;
}

export interface MemberDetails {
  isMember: boolean;
  name: string;
  kennitala: string;
  bankNumber: string;
  bankKennitala: string;
  address: string;
  emailAddress: string;
  mobilePhone: string;
  isVerified: boolean;
  phoneNumber: string;
}

export interface Party {
  id: string;
  fullName: string;
  kennitala: string;
  street: string;
  postalAddressLine1?: string;
  zipCode: string;
  city: string;
  area?: string;
  isDeceased: boolean;
}

export type PerformanceLineType = Line & {
  primaryRole: Role;
  instruments: Instrument[];
}

export interface Producer {
  id: string;
  name: string;
  address: string;
  mainContactName: string;
  mainContactEmail: string;
  kennitala: string;
  zipCode: string;
  city: string;
  mainContactPhoneNumber: string;
  isrcPrefixes: IsrcPrefix[];
}

export interface ProjectOverview {
  project: Project,
  validation: ProjectValidation
}

export interface ProjectValidation {
  basicInfo: boolean,
  contributors: boolean,
  performanceLines: boolean,
  producer: boolean
  tracks: boolean,
  isReadyForSubmission: boolean,
}

export enum ProjectEnum {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  PUBLISHED = 'PUBLISHED',
  RECORDED = 'RECORDED'
}

export interface Project {
  id: string;
  createdBy: string;
  displayArtistName: string;
  isrcPrefix: IsrcPrefix | null;
  name: string;
  producer: Producer | null;
  releaseDate: string;
  statusCode: string;
  statusName: string;
  tracks: Track[] | null;
  isSingle: boolean;
  isEditable: boolean;
  spotifyAlbumId: string;
  rejectReason: string | null;
}

export interface Recording {
  id: string;
  title: string;
  isrc: string;
  artistName: string;
  duration: string;
  releaseDate: Date;
}

export interface Role {
  code: string;
  nameIcelandic: string;
  type: string;
  hasInstruments: boolean;
}

export interface SearchForParties {
  term: string;
  target: SearchTarget;
}

export interface Track {
  id: string;
  projectId: string;
  name: string;
  isrc: string | null;
  duration: string;
  sortOrder: number;
  recordingId: string | null;
  contributors: ContributionLineType[];
  performanceLines: PerformanceLineType[];
  sidenumber: number;
  isEditable: boolean;
  spotifyTrackId: string;
}

export interface ValidationError {
  [id: string]: string[]
}

export interface ValidationException {
  details: { [id: string]: string[] }
}

export enum SearchTarget {
  LOCAL = 'LOCAL',
  NATIONAL_REGISTRY = 'NATIONAL_REGISTRY',
}

export type RecordingWithPerformers = {
  id: string;
  title: string;
  duration: string;
  recordingDate: Date;
  isrc: string;
  performanceLines: LineFlattened[];
}

export type LineFlattened = {
  id: string;
  partyId: string;
  fullName: string;
  kennitala: string;
  primaryRoleCode: string;
  primaryRoleName: string;
  instruments: string[];
}

export type RecordingResult = {
  id: string;
  title: string;
  duration: string;
  artistId: string;
  artistName: string;
  recordingDate: string;
  isrc: string;
  numberOfPerformers: number;
  legacyId: number;
  albums: string;
}

export type RecordingSearch = {
  artistName: string;
  isrc: string;
  title: string;
  albumName: string;
}

export type ClaimForm = {
  memberId: string;
  role: Role;
  instruments: Instrument[];
  countryOfRecording: Country;
}

export type CreateClaimCommand = ClaimForm & {
  recordings: RecordingResult[];
}

export type Claim = {
  id: string;
  artistName: string;
  countryOfRecordingName: string;
  dateCreated: string;
  duration: string;
  instrumentNames: Instrument[];
  isrc: string;
  recordingDate: string;
  recordingId: string;
  recordingTitle: string;
  roleName: string;
  statusCode: string;
  message: string;
}


export type AttachEvidenceCommand = {
  claimId: string;
  evidenceId: string;
  message: string;
}

export type EvidenceType = {
  claimIds: string[];
  file: File;
  description: string;
}

export type ChangeMyBankInfoCommand = {
  bank1: string;
  bank2: string;
  bank3: string;
  bankKennitala: string;
}

export type ChangeMyAddressCommand = {
  street: string;
  zipCode: string;
  city: string;
  countryCode: string;
}