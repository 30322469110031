import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Navigate } from 'react-router-dom';

import LinkButton from 'shared/components/common/LinkButton';
import { useAuth } from 'shared/hooks/useAuth';

const FrontPage = () => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />
  }

  return (
    <div className="main-center">
      <Grid container alignContent="center" alignItems="center" >

        <Grid item className="extra-padding" md={7}>

          <Typography variant="h3" className="extra-margins">Ert þú flytjandi eða útgefandi tónlistar?</Typography>
          <Typography variant="body1" className="extra-margins">
            Hljóðrit.is er vefur á vegum <a href="https://sfh.is">SFH</a> þar sem útgefendur tónlistar geta skráð hana og fengið úthlutaða ISRC kóða.
            <br />
            <br />

            Meðlimir SFH geta séð öll hljóðrit sem þeir eru skráðir á sem flytjendur.
          </Typography>

          <LinkButton
            to="/login"
            color="primary"
            variant="outlined"
            style={{ marginRight: '16px' }}
          >
            Innskrá
          </LinkButton>

          <LinkButton
            to="/register"
            color="primary"
            variant="contained"
            disableElevation
          >
            Nýskrá
          </LinkButton>

        </Grid>

        <Grid item xs={12} md={4} >
          <img className="img-responsive" alt="Forsíðumynd" src="/img/undraw_compose_music_ovo4.svg" />
        </Grid>

      </Grid>
    </div>
  );
};

export default FrontPage;