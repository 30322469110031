import React from 'react';

import AppRoutes from 'AppRoutes';
import BottomSnackbar from 'shared/components/BottomSnackbar';
import Footer from 'App/Footer';
import NavBar from 'App/NavBar';

const Main = () => {
  return <div>
    <NavBar />
    <div className="main-content">
      <div className="main-area">
        <AppRoutes />
      </div>
      <BottomSnackbar />
    </div>
    <Footer />
  </div>
}

export default Main;