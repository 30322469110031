import { getEnvironmentVariables } from 'environment';
import { UserManager, UserManagerSettings } from 'oidc-client';
import React from 'react';
import { AuthState, MessageState } from 'shared/interfaces';

const env = getEnvironmentVariables();

const getSettings = (): UserManagerSettings => ({
  authority: env.AUTH_ENDPOINT,
  client_id: 'spa',
  redirect_uri: `${env.FRONTEND_BASEPATH}/auth-callback`,
  post_logout_redirect_uri: `${env.FRONTEND_BASEPATH}/logout`,
  response_type: 'code',
  scope: 'openid profile email api.read',
  filterProtocolClaims: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  silent_redirect_uri: `${env.FRONTEND_BASEPATH}/silent-refresh.html`,
  // userStore: new WebStorageStateStore({ store: window.localStorage })
  // includeIdTokenInSilentRenew: false
});

export const authState = {
  user: null,
  isInit: false,
  isLoading: false,
  isUserFetched: false,
  userManager: new UserManager(getSettings())
};

export const messageState: MessageState = { type: 'info', message: '' };

export const AuthContext = React.createContext<[AuthState, React.Dispatch<React.SetStateAction<AuthState>>]>([authState, () => { }]);
export const MessageContext =
  React.createContext<[MessageState | null, React.Dispatch<React.SetStateAction<MessageState | null>>]>([messageState, () => { }]);
