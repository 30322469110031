import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Toolbar from '@material-ui/core/Toolbar';

import { useAuth } from 'shared/hooks/useAuth';
import { useMemberInfo } from 'shared/api';
import LinkButton from 'shared/components/common/LinkButton';

const NavBar = () => {
  const { isLoggedIn: loggedIn, logout } = useAuth();
  const classes = useStyles();
  const onLogoutUser = () => logout();

  const { data: memberInfo } = useMemberInfo(loggedIn);
  const isVerified = memberInfo?.isVerified;

  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<boolean | null>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    setAnchorEl(event.currentTarget);

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = () => setMobileMoreAnchorEl(true);

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const handleLogout = () => {
    setAnchorEl(null);
    onLogoutUser();
  };

  const DesktopMenu = () => (
    <Menu
      id="menu-appbar"
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Útskrá</MenuItem>
    </Menu>
  );

  const MobileMenu = () => <div>
    {loggedIn
      ? <>
        <List>
          <ListItem>
            <Icon className={classes.menuButton}>
              <AccountCircle />
            </Icon>
          </ListItem>
          <ListItem className={classes.mobileListItem}>
            <LinkButton className={classes.mobileMenuBotton} to="/dashboard">
              Mínar síður
            </LinkButton>
          </ListItem>

          {isVerified && <ListItem className={classes.mobileListItem}>
            <LinkButton className={classes.mobileMenuBotton} to="/my-info">
              Um meðlim
            </LinkButton>
          </ListItem>}

          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemText classes={{ primary: classes.menuButton }} primary="Útskrá" />
          </ListItem>
        </List>
      </>
      : <>
        <List>
          <ListItem className={classes.mobileListItem}>
            <LinkButton className={classes.mobileMenuBotton} to="/login">
              Innskrá
            </LinkButton>
          </ListItem>
          <ListItem className={classes.mobileListItem}>
            <LinkButton className={classes.mobileMenuBotton} to="/register">
              Nýskrá
            </LinkButton>
          </ListItem>
        </List>
      </>}
  </div>

  return <>
    <div className={classes.container}>
      <AppBar className={classes.navbar} position="static" color="inherit">
        <Toolbar>
          <Grid container className={classes.root} direction="row" justifyContent="space-between" alignItems="center">
            <Grid item className={classes.logoLink}>
              {<Link to={loggedIn ? '/dashboard' : '/'}>
                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                  <div style={{ paddingRight: '8px' }}>
                    <img src="/img/logo.png" style={{ width: '49px' }} />
                  </div>
                  <div>
                    <img src="/img/hl_logo.svg" style={{ height: '16px' }} />
                  </div>
                </div>
              </Link>}
            </Grid>
            <Grid item />
            {loggedIn ? (
              <Grid item className={classes.sectionDesktop}>
                <LinkButton className={classes.menuButton} to="/dashboard">
                  Mínar síður
                </LinkButton>

                {isVerified && <LinkButton className={classes.menuButton} to="/my-info">
                  Um meðlim
                </LinkButton>}

                <IconButton
                  className={classes.menuButton}
                  aria-owns="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                >
                  <AccountCircle fontSize="large" />
                </IconButton>
              </Grid>
            ) : (
              <Grid item className={classes.sectionDesktop}>
                <LinkButton className={classes.menuButton} to="/login">
                  Innskrá
                </LinkButton>
                <LinkButton className={classes.menuButton} to="/register">
                  Nýskrá
                </LinkButton>
              </Grid>
            )}
            <Grid item className={classes.sectionMobile}>
              <IconButton
                className={classes.menuButton}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        style={{ zIndex: 1300 }}
        className={classes.sectionMobile}
        anchor="right"
        open={isMobileMenuOpen}
        onOpen={handleMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={handleMobileMenuClose}
          onKeyDown={handleMobileMenuClose}
        >
          <MobileMenu />
        </div>
      </SwipeableDrawer>
      <DesktopMenu />
    </div>
  </>
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    width: '100%',
    left: 0,
    top: 0,
    zIndex: 1,
  },
  root: {
    flexGrow: 1,
    margin: '0 12%',
  },
  grow: {
    flexGrow: 1,
    height: 'auto',
  },
  menuButton: {
    color: theme.palette.primary.main
  },
  logotext: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },

  mobileMenuBotton: {
    padding: '8px 19px 8px 15px',
  },
  mobileListItem: {
    padding: '0px',
  },
  logoLink: {
    '@media (max-width:560px)': {
      width: '75%',
    },
  },
  photo: {
    height: '48px',
    padding: '8px'
  },
  navbar: {
    background: 'white',
    boxShadow: '0px 2px 2px -1px rgba(0, 0, 0, 0),0px 4px 5px 0px rgba(0, 0, 0, 0),0px 1px 10px 0px rgba(0, 0, 0, 0.1)'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default NavBar;