import { User } from "oidc-client";

export const setLocalStorage = (user: User) => {
  localStorage.setItem('userId', user.profile.preferred_username!);
  localStorage.setItem('token', user.access_token);
  localStorage.setItem('isEmailConfirmed', JSON.stringify(user.profile.email_verified!));
}

export const cleanLocalStorage = () => {
  localStorage.removeItem('userId');
  localStorage.removeItem('token');
  localStorage.removeItem('isEmailConfirmed')
}