import React, { Suspense, useState } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/pickers';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import isLocale from 'date-fns/locale/is';

import { appTheme } from 'shared/appTheme';
import { AuthState, MessageState } from 'shared/interfaces';
import { MessageContext, AuthContext, authState as initAuthState } from 'shared/contexts';
import Main from './App/Main';

const App = () => {
  const [message, setMessage] = useState<MessageState | null>(null);
  const [authData, setAuthData] = useState<AuthState>(initAuthState);

  return <>
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider value={[authData, setAuthData]}>
        <MessageContext.Provider value={[message, setMessage]}>
          <Suspense fallback={<div>Augnablik... </div>}>
            <LocalizationProvider dateAdapter={DateFnsUtils} locale={isLocale}>
              <ThemeProvider theme={theme}>
                <Main />
              </ThemeProvider>
            </LocalizationProvider>
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false} />
        </MessageContext.Provider>
      </AuthContext.Provider>
    </QueryClientProvider>
  </>
}

const theme = createTheme(appTheme);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      retry: false
    }
  }
});

export default App;
