import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  ContributionLineType,
  Country,
  Envelope,
  Instrument,
  Party,
  PerformanceLineType,
  Producer,
  Project,
  RecordingSearch,
  Role,
  SearchForParties,
  SearchTarget,
  Track,
} from 'shared/interfaces';
import * as api from './api';
import { useMutationRefreshCash, useQueryWithDefaultValue } from './helpers';

/// Queries
export const useMyAllocations = () => useQuery(['my-allocations'], api.getMyAllocations);

export const useRecordingDetails = (recordingId: string | undefined) => {
  const fn = () => api.getRecordingDetails(recordingId as string);
  return useQueryWithDefaultValue(null, ['recording-details', recordingId], fn, recordingId, {
    enabled: !!recordingId
  })
}

export const useTracks = (projectId: string | null) => {
  const fn = () => api.getTracksByProjectId(projectId!);
  return useQueryWithDefaultValue([] as Track[], ['tracks', projectId], fn, projectId, { enabled: false });
}

export const useProducer = (producerId: string | null) => {
  const fn = () => api.getProducerById(producerId!)
  return useQueryWithDefaultValue(null, ['producer', producerId], fn, producerId);
}

export const useMyProjects = (userId: string | null) => {
  const fn = () => api.getMyProjects(userId!)
  return useQueryWithDefaultValue([] as Project[], ['my-projects'], fn, userId)
}

export const useMemberInfo = (isLoggedIn: boolean) =>
  useQuery(['member'], api.getMemberInfo, { enabled: !!isLoggedIn });

export const useMemberDetails = () =>
  useQueryWithDefaultValue(null, ['member-details'], api.getMemberDetails);

export const useInstruments = () =>
  useQueryWithDefaultValue([] as Instrument[], ['instruments'], api.getInstruments);

export const useCountries = () =>
  useQueryWithDefaultValue([] as Country[], ['countries'], api.getCountries);

export const usePerformanceRoles = () =>
  useQueryWithDefaultValue([] as Role[], ['performance-roles'], api.getPerformanceRoles);

export const useProject = (projectId: string | null) => {
  const pId = (projectId === 'newsingle' || projectId === 'newproject')
    ? null
    : projectId

  const fn = () => api.getProjectById(pId as string);
  return useQueryWithDefaultValue(null, ['project', pId], fn, pId);
}

export const useGetContributionLines = (projectId: string | null) => {
  const fn = () => api.getContributionLines(projectId!);
  return useQueryWithDefaultValue([] as ContributionLineType[], ['contribution-lines', projectId], fn, projectId, { enabled: false });
}

export const useProjectSummary = (projectId: string | null) => {
  const fn = () => api.getProjectSummary(projectId!)
  return useQueryWithDefaultValue(null, ['project-summary', projectId], fn, projectId);
}

export const useGetAllPerformanceLines = (projectId: string | null) => {
  const fn = () => api.getAllPerformanceLines(projectId!);
  return useQueryWithDefaultValue([] as PerformanceLineType[], ['all-performers', projectId], fn, projectId, { enabled: false });
}

export const useFindRecordings = (term: string | null) => {
  const fn = () => api.findRecordings(term!);
  return useQueryWithDefaultValue(undefined, ['recordings-search', term], fn, term, { enabled: false });
}

export const usePartySearch = (data: SearchForParties | null, showOnlyPerformers: boolean) => {
  const fn = () => api.searchParties(data!, showOnlyPerformers);
  return useQueryWithDefaultValue([] as Party[], ['parties', data, showOnlyPerformers], fn, data, { enabled: false })
}

export const useProducersSearchResults = (term: string, target: SearchTarget) => {
  const fn: () => Promise<Envelope<Producer> | null> = !!term && !!target
    ? () => api.searchForProducers(term, target)
    : () => new Promise(resolve => resolve(null))

  return useQuery(['producerSearchResults', term, target], fn);
}

export const useClaims = () => useQueryWithDefaultValue(null, ['claims'], api.getClaims);

export const useRecordingsForClaimsSearch = (data: RecordingSearch | null) => {
  const fn = () => api.getRecordingsForClaims(data!);
  return useQuery(['claims-search', JSON.stringify(data)], fn, { enabled: !!data });
}

/// Commands

export const useCreateClaim = () => useMutationRefreshCash(api.createClaim, ['claims']);
export const useCreateParty = () => useMutationRefreshCash(api.createParty, ['saved-party']);
export const useAddRecording = () => useMutationRefreshCash(api.addRecording, ['tracks'], (tracks) => tracks[0].projectId);
export const useAddTrack = () => useMutationRefreshCash(api.addTrack, ['tracks'], (track) => track.projectId);
export const useDeleteTrack = () => useMutationRefreshCash(api.deleteTrack, ['tracks'], (track) => track.projectId);
export const useUpdateTrack = () => useMutationRefreshCash(api.updateTrack, ['tracks'], (track) => track.projectId);
export const useResortTracksRemotely = () => useMutationRefreshCash(api.resortTracksRemotely, ['tracks'], (tracks) => tracks[0].projectId);

export const useUpdateSingle = () => useMutationRefreshCash(api.updateSingle, ['project'], (single) => single.project.id);
export const useCreateSingle = () => useMutationRefreshCash(api.createSingle, ['project'], (single) => single.project.id);
export const useUpdateProject = () => useMutationRefreshCash(api.updateProject, ['project'], (project) => project.id);
export const useAddIsrcPrefixToProject = () => useMutationRefreshCash(api.addIsrcPrefixToProject, ['project'], (project) => project.id);
export const useRemoveProducerFromProject = () => useMutationRefreshCash(api.removeProducerFromProject, ['project'], (project) => project.id);
export const useAddProducerFromProject = () => useMutationRefreshCash(api.addProducerToProject, ['project'], (project) => project.id);

export const useDeleteProject = () => useMutationRefreshCash(api.deleteProject, ['my-projects']);

export const useCreateProject = () => useMutation(api.createProject);
export const useAddPerformanceLine = () => useMutation(api.addPerformanceLine);
export const useUpdatePerformanceLine = () => useMutation(api.updatePerformanceLine);
export const usePastePerformanceLines = () => useMutation(api.pastePerformanceLines);
export const useDeletePerformanceLine = () => useMutation(api.deletePerformanceLine);

export const useAddContributionLine = () => useMutation(api.addContributionLine);
export const useDeleteContributionLine = () => useMutation(api.deleteContributionLine);
export const usePasteContributionLines = () => useMutation(api.pasteContributionLines);

export const useRegisterUser = () => useMutation(api.registerUser);

export const useCreateProducer = () => useMutation(api.createProducer);

export const useSubmitProject = () => {
  const queryClient = useQueryClient();

  return useMutation(api.submitProject, {
    onSuccess: (overview) => {
      queryClient.invalidateQueries(['project', overview.project.id])
      queryClient.invalidateQueries(['project-summary', overview.project.id])
    },
  });
}

export const useAttachEvidences = () => useMutation(api.attachEvidences);
export const useChangeBankInfo = () => useMutation(api.updateBankInfo);
export const useChangeAddress = () => useMutation(api.changeAddress);
